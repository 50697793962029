import CustomForm from './custom_form.js';

class SignIn extends CustomForm {
  bindEvent() {
    $('#patient_email').on('keyup', (e) => this.validate(e.target.value))
    $('#patient_password').on('keyup', (e) => this.validate(e.target.value))
    this.checkForm()
  }

  validate(value) {
    this.isValid = value.length > 0
    const submitBtn = $(this.object).find(':input[type=submit]')
    this.isValid ? submitBtn.removeClass('disabled') : submitBtn.addClass('disabled')
  }

  checkForm() {
    setTimeout(() => {
      this.isValid = $('input:-webkit-autofill').length === 2
      $(this.object).find(':input[type=submit]').toggleClass('disabled', !this.isValid)
    }, 1000);
  }
}

window.SignIn = SignIn